@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap");
body {
  font-family: "Albert Sans", sans-serif;
}

.app {
  font-size: 16px;
}

.logo_zein_pemalang {
  width: 45px;
  margin: 0 auto;
  display: block;
}

.header {
  padding-top: 10px;
  padding-bottom: 100px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.header h1 {
  /* color: #de4e00; */
  color: #292929;
  font-size: 2.5em;
  font-weight: bold;
}
.header p {
  /* color: #de4e00; */
  color: #292929;
  font-size: 1.2em;
  margin: 0;
}
.header .hero_image {
  width: 100%;
}

.btn_orange {
  background-color: #de4e00 !important;
  color: white !important;
}
.btn_orange:hover {
  background-color: #ff6a00 !important;
  color: white !important;
}
.btn_orange:active {
  background-color: #c43e00 !important;
  color: white !important;
}
.btn_orange:focus {
  outline: none;
}

.section {
  padding: 50px 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.section.orange {
  background-color: #de4e00;
  color: white;
}
.section.why_us {
  background-color: #f5f5f5;
}
.section.why_us h2 {
  text-align: center;
}
.section.main_menu {
  background-color: #f5f5f5;
}
.section.main_menu h2 {
  text-align: center;
}
.section.main_menu p {
  text-align: center;
  font-size: 1.2em;
}
.section.main_menu img {
  width: 100%;
}
.section.facility {
  background-color: #f5f5f5;
}
.section.facility h2 {
  text-align: center;
}
.section.facility h3 {
  font-size: 1.2em;
  font-weight: bold;
}
.section.facility .facility_icon {
  margin-right: 20px;
  margin-left: 20px;
}
.section.facility .facility_icon img {
  width: 100px;
  border-radius: 20px;
  border: 2px solid #de4e00;
}
.section.facility .end {
  justify-content: end;
}
.section.facility .desc {
  max-width: 600px;
}
.section.services {
  background-color: #fb752d;
}
.section.services h2 {
  text-align: center;
}
.section.services h3 {
  font-size: 1.2em;
  font-weight: bold;
}
@media only screen and (min-width: 600px) {
  .section.services .card {
    height: 424px !important;
  }
}
.section.services .card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.section.payment_channel .bank_logo {
  width: 70%;
  margin: 0 auto;
  display: block;
}
@media only screen and (min-width: 600px) {
  .section.payment_channel .bank_logo {
    width: 300px;
  }
}
.section.footer {
  background-color: #de4e00;
  color: white;
  padding-top: 90px;
  padding-bottom: 20px;
}
.section.footer h4 {
  font-size: 1.2em;
  font-weight: bold;
}
.section.footer a {
  color: white;
  text-decoration: none;
}
.section.menu {
  color: #c43e00;
  padding: 20px 0;
}
.section.menu h1 {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .info.end {
    justify-content: end;
  }
}

