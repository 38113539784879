@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body{
    font-family: "Albert Sans", sans-serif;
}

.app{
    font-size: 16px;
}

.logo_zein_pemalang{
    width: 45px;
    margin: 0 auto;
    display: block;
}

.header{
    // background-color: #de4e00;
    padding-top: 10px;
    padding-bottom: 100px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    h1{
        color: #de4e00;
        font-size: 2.5em;
        font-weight: bold;
    }

    p{
        color: #de4e00;
        font-size: 1.2em;
        margin: 0;
    }

    .hero_image{
        width: 100%;
    }
}

.btn_orange{
    background-color: #de4e00 !important;
    color: white !important;

    &:hover{
        background-color: #ff6a00 !important;
        color: white !important;
    }

    &:active{
        background-color: #c43e00 !important;
        color: white !important;
    }

    &:focus{
        outline: none;
    }
}

.section{
    padding: 50px 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    &.orange{
        background-color: #de4e00;
        color: white;
    }

    &.why_us{
        background-color: #f5f5f5;
        h2{
            text-align: center;
        }
    }

    &.main_menu{
        background-color: #f5f5f5;

        h2{
            text-align: center;
        }

        p{
            text-align: center;
            font-size: 1.2em;
        }
        
        img{
            width: 100%;
        
        }
    }

    &.facility{
        background-color: #f5f5f5;
        h2{
            text-align: center;
        }

        h3{
            font-size: 1.2em;
            font-weight: bold;
        }
    
        .facility_icon{
            margin-right: 20px;
            margin-left: 20px;
            img{
                width: 100px;
                border-radius: 20px;
                border: 2px solid #de4e00;
            }

        }

        .end{
            justify-content: end;
        }

        .desc{
            max-width: 600px;
        }
    }

    &.services{
        background-color: #fb752d;
        h2{
            text-align: center;
        }

        h3{
            font-size: 1.2em;
            font-weight: bold;
        }

        .card{
            @media only screen and (min-width: 600px){
                height: 424px !important;
            }

            img{
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
        }

    }

    &.payment_channel{
        .bank_logo{
            width: 70%;
            margin: 0 auto;
            display: block;

            @media only screen and (min-width: 600px){
                width: 300px;
            }
        }
    }

    &.footer{
        background-color: #de4e00;
        color: white;
        padding-top: 90px;
        padding-bottom: 20px;
        h4{
            font-size: 1.2em;
            font-weight: bold;
        
        }
        a{
            color: white;
            text-decoration: none;
        }
    }

    &.menu{
        color: #c43e00;
        padding: 20px 0;
        h1{
            font-size: 1.5em;
            font-weight: bold;
            text-align: center;
        
        }
    
    }
}

.info{
    &.end{
        @media only screen and (max-width: 600px){
            justify-content: end;
        }
    }
}
